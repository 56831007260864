import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';

import ProductImageAdditional from '@components/common/ProductFullResponsive/ProductImage/additional';
import PictureShow from '@components/common/PictureShow';
import { IProductInList } from '@interfaces/product';

import itemStyles from './../styles';

interface IProductImageProps {
  isNotAvailable?: boolean;
  product: IProductInList;
  productId?: number;
  rightAction?: ReactNode;
  sizeId?: number;
  withLike?: boolean;
}

const ProductImage: React.FunctionComponent<IProductImageProps> = ({
  isNotAvailable,
  product,
  productId,
  rightAction,
  sizeId,
  withLike = true,
}) => {
  const classesItem = itemStyles({});

  const [hoverImage, handleHoverImage] = useState(false);
  const [hoveredImage, handleHoveredImage] = useState(false);
  const [pictureLoaded, changePictureLoaded] = useState(false);
  const [pictureSecondLoaded, changePictureSecondLoaded] = useState(false);

  const currentProductId = productId || product.colors[0]?.productId;
  const currentColor =
    product.colors.find((colorItem) => String(colorItem.productId) === String(currentProductId)) || product.colors[0];
  const imageFirst = currentColor?.pictures[0]?.urlMedium;
  const imageSecond = currentColor?.pictures[1] ? currentColor?.pictures[1].urlMedium : imageFirst;

  return (
    <div
      className={classesItem.productFullImage}
      onMouseEnter={() => {
        handleHoverImage(true);
        if (!hoveredImage) {
          handleHoveredImage(true);
        }
      }}
      onMouseLeave={() => handleHoverImage(false)}
    >
      <PictureShow
        onLoaded={() => changePictureLoaded(true)}
        className={classNames(classesItem.productFullImageImg, {
          [classesItem.productFullImageImgHide]: hoverImage && pictureSecondLoaded,
        })}
        src={imageFirst}
      />
      {hoveredImage && (
        <PictureShow
          onLoaded={() => changePictureSecondLoaded(true)}
          className={classNames(classesItem.productFullImageImg, {
            [classesItem.productFullImageImgHide]: !hoverImage || !pictureSecondLoaded,
          })}
          src={imageSecond}
        />
      )}
      <ProductImageAdditional product={product} pictureLoaded={pictureLoaded} isNotAvailable={isNotAvailable} rightAction={rightAction} sizeId={sizeId} withLike={withLike} />
    </div>
  );
};

export default ProductImage;
