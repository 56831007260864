import React, { ReactNode } from 'react';

import ProductImageAvailability from '@components/common/ProductFullResponsive/ProductImage/Availability';
import ProductImageLike from '@components/common/ProductFullResponsive/ProductImage/Like';
import ProductImageTopActions from '@components/common/ProductFullResponsive/ProductImage/TopActions';
import { IProductInList } from '@interfaces/product';


interface IProductImageProps {
  isNotAvailable?: boolean;
  pictureLoaded: boolean;
  product: IProductInList;
  productId?: number;
  rightAction?: ReactNode;
  sizeId?: number;
  withLike?: boolean;
}

const ProductImageAdditional: React.FunctionComponent<IProductImageProps> = ({
  isNotAvailable,
  pictureLoaded,
  product,
  productId,
  rightAction,
  sizeId,
  withLike = true,
}) => {

  const currentProductId = productId || product.colors[0]?.productId;
  const currentColor =
    product.colors.find((colorItem) => String(colorItem.productId) === String(currentProductId)) || product.colors[0];

  return (
    <>
      {withLike && <ProductImageLike currentProductId={currentProductId} product={product} color={currentColor} />}
      <ProductImageAvailability
        pictureLoaded={pictureLoaded}
        currentProductId={currentProductId}
        product={product}
        isNotAvailable={isNotAvailable}
        sizeId={sizeId}
      />
      <ProductImageTopActions product={product} currentProductId={currentProductId} rightAction={rightAction} />
    </>
  );
};

export default ProductImageAdditional;
